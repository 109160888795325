.rbc-toolbar-label,
.rbc-header{
    font-size: 16px;
    font-weight: bold;
}
.rbc-toolbar button,
.rbc-event {
    color: #ffffff;
    border-radius: 4px;
}
.rbc-toolbar button {
    background-color: #1976d2;
    margin: 0 4px;
}

.rbc-event {
    background-color: #003366 !important;
    padding: 4px;
    font-weight: bold;
    border-radius: 14px;
    margin: 0;
}

.rbc-today {
    background-color: #e3f2fd;
}

.rbc-off-range {
    color: #bdbdbd;
}
